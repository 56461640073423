/*
$blue: darkslateblue;
$bg-body: antiquewhite;

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
}

// A general comment that should be
// stripped out in production
body {
  color: $blue;
  background-color: $bg-body;
  display: flex;
  align-items: center;
  flex-direction: column;
}

main {
  display: flex;
  flex-grow: 1;
  align-items: center;

  img {
    width: 6rem;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin-right: 0.25em;
  vertical-align: middle;
  font-size: 1.5em;
  fill: currentColor;
  pointer-events: none;
}
*/

.custom-header-bar:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  width: 45vw;
  background-image: linear-gradient(150deg, #cb0101 0%, #cb0101 30%, #fb0101 70%, #fb0101 100%);
  border-radius: 4px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.06);
}

@media (max-width: 1200px) {
  .custom-header-bar:before {
      width: 110vw;
  }
}
